import React from 'react';
import Swal from 'sweetalert2';
import { Link, Redirect } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faBan, faCalendarAlt, faCheck, faEdit, faFilter, faHashtag, faPlus, faTimes, faTrashAlt, faWindowRestore } from '@fortawesome/free-solid-svg-icons';

class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        loadTerminos: true,
        disableTipoSociedad: true,
        navigate: false,
        datosCargados: false,
        // titularidad_transparencia: '',
        compromiso_transparencia: '',
        autorizacion_datos_peronsales: '',
        tipo_tercero: '',
        tipo_sociedad: '',
        formularios: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    activarTipoSociedad = (e) => {
        switch (e.target.value) {
            case '1':
                this.setState({ disableTipoSociedad: false });
                break;
            case '6':
                this.setState({ disableTipoSociedad: false })
                break;
            default:
                document.getElementById('tipo_sociedad').selectedIndex = '0';
                this.setState({ disableTipoSociedad: true })
                break;
        }
    }
    aceptarTerminos = (e, tipo_campo) => {
        switch (tipo_campo) {
            case 'datos_personales':
                if (e.target.value === '2') {
                    Swal.fire({
                        title: 'Está seguro de No aceptar los Términos de Autorización de Datos Peronsales?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'No Aceptar',
                        denyButtonText: `Cancelar`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var id_usuario = document.getElementById('id_usuario').value;
                            authAxios.get("formulario/close_session/" + id_usuario)
                                .then((datosRespuesta) => {
                                    console.log(datosRespuesta);
                                    Swal.fire('Sesión Cerrada!', datosRespuesta.data.msj, 'success')
                                    window.location.href = "/";
                                    window.localStorage.clear();
                                })
                                .catch(console.log);
                        } else {
                            document.getElementById("autorizacion_datos_peronsales").selectedIndex = "0";
                        }
                    })
                }
                break;
            case 'compromiso_sagrilaft':
                if (e.target.value === '2') {
                    Swal.fire({
                        title: 'Está seguro de No aceptar los Términos de Compromiso Sagrilaft?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'No Aceptar',
                        denyButtonText: `Cancelar`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var id_usuario = document.getElementById('id_usuario').value;
                            authAxios.get("formulario/close_session/" + id_usuario)
                                .then((datosRespuesta) => {
                                    console.log(datosRespuesta);
                                    Swal.fire('Sesión Cerrada!', datosRespuesta.data.msj, 'success')
                                    window.location.href = "/";
                                    window.localStorage.clear();
                                })
                                .catch(console.log);
                        } else {
                            document.getElementById("compromiso_transparencia").selectedIndex = "0";
                        }
                    })
                }
                break;
        }
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el Formulario?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("formulario/delete/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                        this.ListarFormularios(1);
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    cambiarEstado = (e) => {
        if (!this.state.loadTerminos) {
            this.setState({ loadTerminos: true })
        } else {
            this.setState({ loadTerminos: false })
        }
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const { compromiso_transparencia, autorizacion_datos_peronsales, tipo_tercero, tipo_sociedad } = this.state;
        var errores = [];
        // if (!titularidad_transparencia) { errores.push("error_titularidad_transparencia"); }
        if (!compromiso_transparencia) { errores.push("error_compromiso_transparencia"); }
        if (!autorizacion_datos_peronsales) { errores.push("error_autorizacion_datos_peronsales"); }
        if (!tipo_tercero) { errores.push("error_tipo_tercero"); }
        if (tipo_tercero === '6') {
            if (!tipo_sociedad) { errores.push("error_tipo_sociedad"); }
        }
        this.setState({ errores: errores });
        console.log(errores);
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form-transparencia');
        let formData = new FormData(form);
        console.log(formData);
        Swal.showLoading();
        authAxios.post("formulario/register", formData)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Formularios!',
                    datosRespuesta.data.msj,
                    'success'
                )
                Swal.close();
                // this.props.history.push("/UpTerceros/" + datosRespuesta.data.id);
                window.location.href = '/UpTerceros/' + datosRespuesta.data.id;
            })
            .catch(console.log)
        // this.setState({ navigate: true });
        // this.props.history.push("/AddProveedoresNacionalesNaturales");
        // return <Redirect to="/AddProovedoresNacionalesNaturales" push={true} />
    }
    rechazarFormulario = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Está seguro de Rechazar el Proceso?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Rechazar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                var id_usuario = document.getElementById('id_usuario').value;
                authAxios.get("formulario/close_session/" + id_usuario)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Sesión Cerrada!', datosRespuesta.data.msj, 'success')
                        window.location.href = "/";
                        window.localStorage.clear();
                    })
                    .catch(console.log);
                // window.location.href = "/";
                // window.localStorage.clear();
            }
        })
    }
    ListarFormularios(page) {
        Swal.showLoading();
        let id_empresa = window.localStorage.getItem('id_empresa');
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        authAxios.get("formulario/index/" + bname + "/" + id_empresa + "?page=" + page)
            .then((datosRespuesta) => {
                Swal.close();
                console.log(datosRespuesta);
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                console.log(status);
                this.setState({ datosCargados: true, load: '', formularios: datosRespuesta.data.data.data, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
                Swal.close();
            })
        .catch(console.log);
    }
    componentDidMount() {
        const timeout = window.localStorage.getItem('timeout');
        // alert("Now: " + Date.now() + " - TimeOut: " + timeout);
        if (Date.now() > timeout) {
            // alert("Entra");
            window.location.href = "/";
            window.localStorage.clear();
            Swal.fire({
                title: 'Formularios',
                text: 'Sesión Expirada',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/";
                    window.localStorage.clear();
                }
            })
        }
        const date = new Date();
        const newDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        this.setState({
            fecha_diligenciamiento : newDate,
            nombre_transparencia: window.localStorage.getItem('name'),
            documento_transparencia: window.localStorage.getItem('identification'),
            tipo_documento_transparencia: window.localStorage.getItem('identification_type'),
            tipo_tercero: window.localStorage.getItem('tipo_tercero')
        });
        switch (window.localStorage.getItem('tipo_tercero')) {
            case '1':
                this.setState({ disableTipoSociedad: false });
                break;
            case '6':
                this.setState({ disableTipoSociedad: false })
                break;
            default:
                document.getElementById('tipo_sociedad').selectedIndex = '0';
                this.setState({ disableTipoSociedad: true })
                break;
        }
        this.ListarFormularios(1);
    }
    render() {
        const { load, loadTerminos, disableTipoSociedad, navigate, formularios,
                compromiso_transparencia, autorizacion_datos_peronsales, tipo_tercero, tipo_sociedad,
                pagina, ultimo, actual, errores } = this.state;
        // if (navigate) {
        //     return <Redirect to="/AddTerceros" push={true} />
        // }
        return (
            <>
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header">
                                <button className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#TerminosCondicionesModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Inicio Registro Tercero</button>
                            </div>
                            <div className="card-body">
                                <h4>Lista Formularios</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-xl-6">
                                        <input style={{textTransform: 'uppercase'}} type="text" id="bnombre" name="bnombre" placeholder="FORMULARIO" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-1">
                                        <button id="bt" name="bt" className="btn btn-secondary btn-sm" onClick={() => this.ListarFormularios(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th className="align-middle">FECHA SOLICITUD</th>
                                                <th className="align-middle">TIPO TERCERO</th>
                                                <th className="align-middle">TIPO FORMULARIO</th>
                                                <th className="align-middle">ESTADO</th>
                                                <th className="align-middle">OPCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {
                                                formularios.map((itemFormularios) => (
                                                    <tr key={itemFormularios.id}>
                                                        <td>{itemFormularios.fecha_solicitud}</td>
                                                        {
                                                            (() => {
                                                                switch (itemFormularios.tipo_tercero) {
                                                                    case 1:
                                                                        return (<td>ACCIONISTA</td>)
                                                                    case 2:
                                                                        return (<td>CLIENTE</td>)
                                                                    case 3:
                                                                        return (<td>EMPLEADO</td>)
                                                                    case 4:
                                                                        return (<td>MIEMBRO JUNTA DIRECTIVA</td>)
                                                                    case 5:
                                                                        return (<td>PROVEEDOR EXTERIOR</td>)
                                                                    case 6:
                                                                        return (<td>PROVEEDOR NACIONAL PERSONA JURÍDICA</td>)
                                                                    case 7:
                                                                        return (<td>PROVEEDOR NACIONAL PERSONA NATURAL</td>)
                                                                }
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                                switch (itemFormularios.primera_vez) {
                                                                    case 1:
                                                                        return (<td>PRIMERA VEZ</td>)
                                                                    case 2:
                                                                        return (<td>ACTUALIZACION</td>)
                                                                }
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                                switch (itemFormularios.estado) {
                                                                    case '1':
                                                                        return (<td>EN PROCESO</td>)
                                                                    case '2':
                                                                        return (<td>CANCELADO</td>)
                                                                    case '3':
                                                                        return (<td>ENVIADO</td>)
                                                                }
                                                            })()
                                                        }
                                                        <td>
                                                            <div className="btn-group" role="group" aria-label="">
                                                                {
                                                                    (() => {
                                                                        switch (itemFormularios.estado) {
                                                                            case '1':
                                                                                return (
                                                                                    <>
                                                                                        <Link
                                                                                            className="btn btn-primary btn-sm"
                                                                                            to={"/UpTerceros/" + itemFormularios.id}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                        </Link>&nbsp;&nbsp;
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                            onClick={() =>
                                                                                                this.borrarRegistros(itemFormularios.id)
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                                        </button>
                                                                                    </>
                                                                                )
                                                                            default:
                                                                                return(
                                                                                    <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">ENVIADO</span>
                                                                                );
                                                                        }
                                                                    })()
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarFormularios(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarFormularios(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                        {(() => {
                                            let p = [];
                                            p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarTercero(actual)}>{actual}</a></li>);
                                            return p;
                                        })()}
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarFormularios(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarFormularios(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="card-footer text-muted">
                                <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="TerminosCondicionesModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="TerminosCondicionesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="TerminosCondicionesModalLabel">Formulario Registro de Terceros</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id='form-transparencia'>
                                    <div className='row'>
                                        <div className='col-xl-6'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    CLASIFICACIÓN GENERAL
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className='row g-3'>
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_tercero">Tipo Tercero</label>
                                                                    <input type="hidden" id='id_tipo_tercero' name='id_tipo_tercero' value={tipo_tercero} />
                                                                    <select name="tipo_tercero"
                                                                        id="tipo_tercero"
                                                                        value={tipo_tercero}
                                                                        options={tipo_tercero}
                                                                        className={((this.verificarError("error_tipo_tercero")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValor(e);
                                                                            this.activarTipoSociedad(e);
                                                                        }} disabled>
                                                                            <option value="">Seleccione el Tipo Tercero</option>
                                                                            <option value="1">ACCIONISTA</option>
                                                                            <option value="2">CLIENTE</option>
                                                                            <option value="3">EMPLEADO</option>
                                                                            <option value="4">MIEMBRO JUNTA DIRECTIVA</option>
                                                                            <option value="5">PROVEEDOR EXTERIOR</option>
                                                                            <option value="6">PROVEEDOR NACIONAL PERSONA JURÍDICA</option>
                                                                            <option value="7">PROVEEDOR NACIONAL PERSONA NATURAL</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Tercero</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_sociedad">Tipo Sociedad</label>
                                                                    <select name="tipo_sociedad"
                                                                        id="tipo_sociedad"
                                                                        className={((this.verificarError("error_tipo_sociedad")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor} disabled={disableTipoSociedad}>
                                                                            <option value="">Seleccione el Tipo Sociedad</option>
                                                                            <option value="1">SOCIEDAD POR ACCIONES SIMPLIFICADAS (S.A.S.)</option>
                                                                            <option value="2">SOCIEDAD ANÓNIMA (S.A.)</option>
                                                                            <option value="3">SOCIEDAD LIMITADA (LTDA.)</option>
                                                                            <option value="4">ENTIDAD SIN ÁNIMO DE LUCRO (E.S.A.L.)</option>
                                                                            <option value="5">SUCURSAL DE SOCIEDAD EXTRANJERA (S.S.E.)</option>
                                                                            <option value="6">SOCIEDAD COLECTIVA (S.C.)</option>
                                                                            <option value="7">SOCIEDAD EN COMANDITA POR ACCIONES (S.C.A)</option>
                                                                            <option value="8">SOCIEDAD EN COMANDITA SIMPLE (S.C.S.)</option>
                                                                            <option value="9">EMPRESA UNIPERSONAL</option>
                                                                            <option value="10">SOCIEDAD ASOCIATIVA DE TRABAJO (S.A.T.)</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Sociedad</small>
                                                                </div>
                                                            </div>
                                                            {/* <hr style={{ marginBottom: 0 }} />
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <div className="">
                                                                        <span style={{ textTransform: 'uppercase' }}>
                                                                            <a href='#' target="_blank">Politicas y Condiciones 1</a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <div className="">
                                                                        <span style={{ textTransform: 'uppercase' }}>
                                                                            <a href='#' target="_blank">Politicas y Condiciones 2</a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-6'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    AYUDA
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                    <p>
                                                                        El formulario consta de 9 secciones o modulos los cuales deberá diligenciar en su totalidad antes de enviarlo.
                                                                    </p>
                                                                    <p>
                                                                        En esta sección se deben ingresar los datos que permiten clasificar su organización por el tipo de empresa y de estructura organizacional, por favor tenga presente que esta clasificación solo se realizará una vez y los datos no pueden ser modificados.
                                                                    </p>
                                                                    <p>
                                                                        El sistema establece un plazo de 8 días para completar el proceso de registro, si se pierde el acceso puede solicitar al contacto que lo invitó que se extienda el plazo.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    AUTORIZACIONES
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                    <p>
                                                                        Al completar y enviar el formulario de registro/actualización de terceros la contraparte:
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* <hr style={{ marginTop: 0 }} /> */}
                                                            <div className='row g-3'>
                                                                {/* <div className="col-md-4">
                                                                    <label htmlFor="titularidad_transparencia">Titularidad</label>
                                                                    <select name="titularidad_transparencia"
                                                                        id="titularidad_transparencia"
                                                                        className={((this.verificarError("error_titularidad_transparencia")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValor(e);
                                                                            this.aceptarTerminos(e, 'titularidad');
                                                                        }}>
                                                                            <option value="">Seleccione la Titularidad</option>
                                                                            <option value="1">SI</option>
                                                                            <option value="2">NO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Titularidad</small>
                                                                </div> */}
                                                                <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                    <p>
                                                                        Manifiesta el titular que la presente autorización fue solicitada, puesta en conocimiento y acepta otorgarla antes de entregar sus datos personales y que la suscribe de forma libre y voluntaria una vez leída en su totalidad.
                                                                    </p>
                                                                    <p>
                                                                        Estimado usuario: a continuación, usted encontrará la autorización de tratamiento de datos personales para terceros relacionados de las empresas del Grupo IAE. Documento mediante el cual usted declara, conoce y acepta:
                                                                    </p>
                                                                    <ol>
                                                                        <li style={{ listStyleType: 'inherit'}}>Que TERMOMORICHAL S.A.S. - PROELECTRICA S.A.S. E.S.P. - GENSER POWER COLOMBIA - GENSER POWER S.A.S. E.S.P. - ENELCA S.A. E.S.P. serán responsables del tratamiento de los datos personales aportados en este formulario.</li>
                                                                        <li style={{ listStyleType: 'inherit'}}>Que los datos suministrados por mi cuenta son veraces, comprobables y vigentes.</li>
                                                                        <li style={{ listStyleType: 'inherit'}}>Que el tratamiento de sus datos puede conllevar la realización de operaciones tales como: recolección, obtención, organización, almacenamiento o conservación, custodia, supresión, consulta, uso, cesión, transmisión y/o transferencia nacional o internacional.</li>
                                                                        <li style={{ listStyleType: 'inherit'}}>Que el suministro de datos sensibles tales como: los datos relativos a la salud, la vida sexual y los datos biométricos NO, es obligatorio y, por ende, es facultativo.</li>
                                                                        <li style={{ listStyleType: 'inherit'}}>Que las empresas del grupo IAE podrán usar sus datos para las finalidades allí descritas.</li>
                                                                        <li style={{ listStyleType: 'inherit'}}>Que sabe cuáles son los derechos que tiene en relación con la protección de datos y los mecanismos que han dispuesto TERMOMORICHAL S.A.S. - PROELECTRICA S.A.S. E.S.P. - GENSER POWER COLOMBIA - GENSER POWER S.A.S. E.S.P. - ENELCA S.A. E.S.P. para su ejercicio.</li>
                                                                    </ol>
                                                                    <p>
                                                                        Le advertimos:
                                                                    </p>
                                                                    <ol>
                                                                        <li style={{ listStyleType: 'inherit'}}>LEA atentamente la autorización.</li>
                                                                        <li style={{ listStyleType: 'inherit'}}>Que el otorgamiento de la autorización para el tratamiento de sus datos personales es FACULTATIVA.</li>
                                                                        <li style={{ listStyleType: 'inherit'}}>En caso de no autorizar el tratamiento de datos personales, TERMOMORICHAL S.A.S. - PROELECTRICA S.A.S. E.S.P. - GENSER POWER COLOMBIA - GENSER POWER S.A.S. E.S.P. - ENELCA S.A. E.S.P. no podrá realizar, entre otras, actividades tales como: atender sus PQRS en relación con el vínculo sostenido con la Compañía, registrarlo en nuestras bases de datos en su calidad de tercero relacionado, contactarlo para fines del establecimiento, iniciación, ejecución y/o culminación de la relación comercial, procesar cuentas de cobro etc.</li>
                                                                    </ol>
                                                                    <p>
                                                                        <a style={{ textDecoration: 'none' }} target='_blank' href='https://genserpower.com/wp-content/uploads/2024/11/POTH-01-POLITICA-GENERAL-DE-PROTECCION-DE-DATOS-PERSONALES-GENSER-POWER-COL.pdf'>Política general de protección de datos personales GENSER POWER COLOMBIA</a>
                                                                    </p>
                                                                    <p>
                                                                        <button type='button' className="btn btn-primary btn-sm" data-bs-toggle='modal' data-bs-target="#AutorizacionModal" slot='end'><FontAwesomeIcon icon={faCheck} />&nbsp;&nbsp;Autorización de Datos</button>
                                                                        {/* <span style={{ textTransform: 'uppercase' }}>
                                                                            <a href='#' target="_blank">Politicas y Condiciones 1</a>
                                                                        </span> */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='row g-3'>
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="autorizacion_datos_peronsales">Aut. Datos Personales</label>
                                                                    <select name="autorizacion_datos_peronsales"
                                                                        id="autorizacion_datos_peronsales"
                                                                        className={((this.verificarError("error_autorizacion_datos_peronsales")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValor(e);
                                                                            this.aceptarTerminos(e, 'datos_personales');
                                                                        }} >
                                                                            <option value="">Seleccione la Autorización</option>
                                                                            <option value="1">SI</option>
                                                                            <option value="2">NO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Autorización</small>
                                                                    {/* <p style={{ marginBottom: 0 }}>
                                                                        <span style={{ textTransform: 'uppercase' }}>
                                                                            <a style={{ fontSize: 12 }} href='#' target="_blank">Politicas y Condiciones 2</a>
                                                                        </span>
                                                                    </p> */}
                                                                </div>
                                                            </div>
                                                            <hr style={{ marginBottom: 0 }} />
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                    <p>
                                                                        Manifiesta que se compromete a aceptar cumplir con las medidas preventivas y en general del SAGRILAFT (Sistema de Autocontrol y Gestión del Riesgo Integral del Lavado de Activos, Financiación del Terrorismo y Financiación de la Proliferación de Armas de Destrucción Masiva "LA/FT/FPADM") y el PAEC (Programa de Anticorrupción, Ética y Cumplimiento) de las Empresas, de acuerdo con las directrices de la Superintendencia de Sociedades y demás entes de control.
                                                                    </p>
                                                                </div>
                                                                <div style={{ marginTop: 0 }} className="col-md-4">
                                                                    <label htmlFor="compromiso_transparencia">Compromiso</label>
                                                                    <input type="hidden" name='id_usuario' id='id_usuario' value={window.localStorage.getItem('id_usuario')} />
                                                                    <input type="hidden" name='id_empresa' id='id_empresa' value={window.localStorage.getItem('id_empresa')} />
                                                                    <select name="compromiso_transparencia"
                                                                        id="compromiso_transparencia"
                                                                        className={((this.verificarError("error_compromiso_transparencia")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={(e) => {
                                                                            this.cambioValor(e);
                                                                            this.aceptarTerminos(e, 'compromiso_sagrilaft');
                                                                        }}>
                                                                            <option value="">Seleccione el Compromiso</option>
                                                                            <option value="1">SI</option>
                                                                            <option value="2">NO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Compromiso</small>
                                                                    {/* <p style={{ marginBottom: 0 }}>
                                                                        <span style={{ textTransform: 'uppercase' }}>
                                                                            <a style={{ fontSize: 12 }} href='#' target="_blank">Politicas y Condiciones 1</a>
                                                                        </span>
                                                                    </p> */}
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input border border-info" type="checkbox" value="" id="terminos_condiciones" onClick={this.cambiarEstado} />
                                                                        <label className="form-check-label" htmlFor="terminos_condiciones">
                                                                            Estoy de acuerdo con los Términos y Condiciones y Políticas de Seguridad
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <hr />
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <hr />
                                                    <div className='row'>
                                                        <div className='col-xl-12 text-center'>
                                                            <div className="btn-group" role="group" aria-label="">
                                                                <button type="submit" className="btn btn-success btn-sm" disabled={loadTerminos ? true : false} onClick={this.enviarDatos}><FontAwesomeIcon icon={faCheck} />&nbsp;&nbsp;Aceptar</button>&nbsp;&nbsp;
                                                                <button type='button' className="btn btn-danger btn-sm" onClick={this.rechazarFormulario}><FontAwesomeIcon icon={faBan} />&nbsp;&nbsp;Rechazar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="AutorizacionModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="AutorizacionModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="AutorizacionModalLabel">Autorización Datos Personales</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-header text-center">
                                        AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES CLIENTES Y PROVEEDORES
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row g-3">
                                                    <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                        <p>
                                                            Por medio de la presente aprobación, se autoriza a la empresa: Genser Power S.A.S. E.S.P., Genser Power Colombia, Termomorichal S.A.S., Proelectrica S.A.S. E.S.P., Enelca S.A.S. E.S.P., Enrevsa E.S.P., en adelante <b>LA COMPAÑÍA</b> para que esta, en calidad de responsable del tratamiento de la información, pueda realizar la recolección, almacenamiento, uso, consulta, actualización, circulación, supresión, transmisión, cesión y actualización de los datos personales de acuerdo con las finalidades respectivas.
                                                        </p>
                                                        <p><b>DERECHOS DEL TITULAR</b></p>
                                                        <p>
                                                            Los derechos de los titulares de los datos son los previstos en la Constitución Política y en la Ley Estatutaria 1581 de 2012, especialmente los siguientes:
                                                        </p>
                                                        <ol>
                                                            <li style={{ listStyleType: 'inherit'}}>Acceder en forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento.</li>
                                                            <li style={{ listStyleType: 'inherit'}}>Solicitar la actualización y rectificación de su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o a aquellos cuyo tratamiento esté prohibido o no haya sido autorizado.</li>
                                                            <li style={{ listStyleType: 'inherit'}}>Solicitar prueba de la autorización otorgada.</li>
                                                            <li style={{ listStyleType: 'inherit'}}>Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente.</li>
                                                            <li style={{ listStyleType: 'inherit'}}>Revocar la autorización y/o solicitar la supresión del dato, a menos que exista un deber legal o contractual que haga imperativo conservar la información.</li>
                                                            <li style={{ listStyleType: 'inherit'}}>Abstenerse de responder las preguntas sobre datos sensibles o sobre datos de niños y adolescentes.</li>
                                                            <li style={{ listStyleType: 'inherit'}}>Ser informado por <b>LA COMPAÑÍA</b>, previa solicitud, respecto del uso que se les ha dado a sus datos personales.</li>
                                                        </ol>
                                                        <p>
                                                            Estos derechos los podrá ejercer a través del correo electrónico <b>oficialprotecciondatos@genserpower.com</b> dispuesto por <b>LA COMPAÑÍA</b> para la atención de requerimientos relacionados con el tratamiento de los datos personales y el ejercicio de los derechos mencionados en esta autorización
                                                        </p>
                                                        <p>
                                                            Por todo lo anterior, se autoriza a <b>LA COMPAÑÍA</b> para que trate los datos personales de acuerdo con la Ley y la Política de Protección de Datos Personales, la cual puede consultar EL TITULAR en cualquier momento a través de la página web de <b>LA COMPAÑÍA</b>.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (() => {
                                        switch (tipo_tercero) {
                                            case '2':
                                                return (
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            FINALIDADES DE USO DE LOS DATOS PERSONALES RECOLECTADOS DE CLIENTES
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Los datos personales otorgados por los CLIENTES serán tratados para las siguientes finalidades por parte de la empresa: Genser Power S.A.S. E.S.P., Genser Power Colombia, Termomorichal S.A.S., Proelectrica S.A.S. E.S.P., Enelca S.A.S. E.S.P., Enrevsa E.S.P., en adelante <b>LA COMPAÑÍA</b>:
                                                                            </p>
                                                                            <ol>
                                                                                <li style={{ listStyleType: 'inherit'}}>Gestionar toda la información que sea necesaria para el desarrollo de la actividad económica adelantada por <b>LA COMPAÑÍA</b> en relación con las actividades de generación de energía eléctrica, comercialización de energía eléctrica y mantenimiento y reparación especializado de equipo eléctrica, entre otros.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Informar y mantener actualizados a los Titulares de la información sobre los asuntos que sean de su interés con relación a la gestión adelantada por <b>LA COMPAÑÍA</b>.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Contactar a los clientes de la Compañía con el propósito de establecer relaciones comerciales en desarrollo de las actividades.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Realizar encuestas de satisfacción de los productos y/o servicios prestados por <b>LA COMPAÑÍA</b>, para poder verificar el funcionamiento del objeto social.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Llevar a cabo el control de clientes de la compañía para el desarrollo de la gestión administrativa, contable y fiscal de <b>LA COMPAÑÍA</b>, en relación con la emisión de facturas, cobros y reporte de pagos.</li>
                                                                            </ol>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            case '5':
                                            case '6':
                                            case '7':
                                                return (
                                                    <div className="card">
                                                        <div className="card-header text-center">
                                                            FINALIDADES DE USO DE LOS DATOS PERSONALES RECOLECTADOS DE PROVEEDORES
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xl-12">
                                                                    <div className="row g-3">
                                                                        <div style={{ textAlign: 'justify', fontSize: '13px' }} className='col-md-12'>
                                                                            <p>
                                                                                Los datos personales otorgados por los CLIENTES serán tratados para las siguientes finalidades por parte de la empresa: Genser Power S.A.S. E.S.P., Genser Power Colombia, Termomorichal S.A.S., Proelectrica S.A.S. E.S.P., Enelca S.A.S. E.S.P., Enrevsa E.S.P., en adelante <b>LA COMPAÑÍA</b>:
                                                                            </p>
                                                                            <ol>
                                                                                <li style={{ listStyleType: 'inherit'}}>Contactar y contratar a proveedores de productos o servicios que <b>LA COMPAÑÍA</b> requiera para el normal funcionamiento de su operación.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Gestionar toda la información que sea necesaria para el desarrollo de la actividad económica adelantada por el proveedor.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Recibir adecuadamente los servicios contratados, así como informar y mantener actualizados a los Titulares de la información sobre los asuntos que sean de su interés.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Llevar a cabo la consulta en listas vinculantes y restrictivas a todos sus proveedores.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Realizar encuestas de satisfacción de los productos y/o servicios prestados por <b>LA COMPAÑÍA</b> para poder verificar el funcionamiento del objeto social.</li>
                                                                                <li style={{ listStyleType: 'inherit'}}>Llevar a cabo el control de proveedores de <b>LA COMPAÑÍA</b> para el desarrollo de la gestión administrativa, contable y fiscal, en relación con pagos de facturas y cuentas cobros.</li>
                                                                            </ol>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    })()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Listar;